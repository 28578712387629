import axios from "axios";
import {API_URL} from "@/const";
import { saveAs } from "file-saver";
import moment from "moment/moment";

export default {
  data() {
    return {
        form: [],
        isDisableEditButton: false,
    };
  },
  methods: {
    //事前協議書取得API
    priorConsultationDetailApi(id) {
      const URL = API_URL.PRIORCONSULTATION.INFO + id;
      return axios
          .get(URL)
          .then((res) => {
            this.form = res.data;
            const entrustIdsList = res.data.routeList.map((r) => r.entrustId);
            this.form.entrustIds = entrustIdsList.filter(
              (val, index) => entrustIdsList.indexOf(val) == index
            ).join(",");
            // 事前協議書は協議書期間終了日から 89目まで変更可能、90日目以降は変更不可でボタンが非活性になる
            const today = moment();
            const priorConsultationEndDate = moment(res.data.priorConsultationEndDate);
            const daysDiff = today.diff(priorConsultationEndDate, "days");
            this.isDisableEditButton = daysDiff >= 90;
          })
          .catch((err) => {
            console.log(err);
          });
    },

    //事前協議書削除API
    deletePriorConsultationApi(id) {
      const isActive = false; //削除APIなので
      const URL = API_URL.PRIORCONSULTATION.DELETE + id;
      return axios
          .patch(URL, {isActive})
          .then((res) => {
            // 一覧画面へ
            this.$router.push(`/priorconsultation`);
            console.log(res);
          })
          .catch((err) => {
            // 一覧画面へ
            this.$router.push(`/priorconsultation`);
            err.response
              ? this.errMsgs.push(err.response.data.message)
              : console.log(err);
          });
    },
    //事前協議書ファイルダウンロードAPI
    downloadPriorConsultationFileApi(id, fileName) {
      axios
          .get(API_URL.PRIORCONSULTATION.FILE + id, {
            responseType: "blob",
          })
          .then((res) => {
            const blob = new Blob([res.data], {
              type: res.data.type,
            });
            saveAs(blob, fileName);
          })
          .catch((err) => {
            console.log(err);
            if (err.response && err.response.status) {
              if (err.response.status == 403) {
                this.$router.push("/error/403");
              }
            }
          });
    },
  },
};
