<template>
  <div class="l-wrapper loggedin">
    <AppHeader pageTitle="事前協議書詳細" />
    <AppSideMenu />
    <main id="main" class="l-main">
      <div class="l-container">
        <div class="c-inputWrap">
          <div class="c-inputWrap__items__cols l-area-side">
            <label>登録日 :</label>
          </div>
          <div class="c-inputWrap__items__cols">
            {{ form.createDate | dateJpYMDddSlackFormat }}
          </div>
        </div>
        <ErrorMessage :errMsgs="errMsgs" />
        <ToastMessage :toastMsg.sync="toastMsg" />

        <!--基本情報-->
        <div class="l-contents l-box">
          <div class="l-box">
            <h3 class="c-lead icon">基本情報</h3>
          </div>
          <div class="c-inputWrap">
            <div class="c-inputWrap__label">
              <label>事前協議書管理番号</label>
            </div>
            <div class="c-inputWrap__items">{{  form.priorConsultationNo | blankText }}</div>
          </div>

          <div class="c-inputWrap">
            <div class="c-inputWrap__label">
              <label>搬入先自治体</label>
            </div>
            <div class="c-inputWrap__items">{{ form.targetMunicipalityName | blankText }}</div>
          </div>

          <div class="c-inputWrap">
            <div class="c-inputWrap__label">
              <label>排出事業者</label>
            </div>
            <div v-if="form.generateCompanyInfo" class="c-inputWrap__items">
              {{ form.generateCompanyInfo ? form.generateCompanyInfo.name : "" }}
            </div>
          </div>

          <div class="c-inputWrap">
            <div class="c-inputWrap__label">
              <label>廃棄物種類</label>
            </div>
            <div class="c-inputWrap__items">
              {{ form.jwnetItemTypeInfo ? form.jwnetItemTypeInfo.name : "" }}
            </div>
          </div>

          <div class="c-inputWrap">
            <div class="c-inputWrap__label">
              <label>ルート</label>
            </div>
          </div>
          <div v-if="form.routeList" class="c-infoList__wrap">
            <div v-if="form.routeList.length" class="c-infoList scroll l-box">
              <div class="c-infoList__row head">
                <div class="c-infoList__cell md-s">ルート名</div>
                <div class="c-infoList__cell md-s">排出事業場</div>
                <div class="c-infoList__cell md-s">廃棄物名称</div>
                <div class="c-infoList__cell md">運搬事業者</div>
                <div class="c-infoList__cell md-s">処分事業者</div>
                <div class="c-infoList__cell md-s">処分場住所</div>
              </div>
              <div
                  v-for="item of this.form.routeList"
                  :key="item.routeId"
                  class="c-infoList__row list"
              >
                <div class="c-infoList__cell md-s text-dotted">{{ item.routeName }}</div>
                <div class="c-infoList__cell md-s text-dotted">{{ item.generateStoreInfo ? item.generateStoreInfo.name : "" }}</div>
                <div class="c-infoList__cell md-s text-dotted">{{ item.wasteNameInfo ? item.wasteNameInfo.name : "" }}</div>
                <div class="c-infoList__cell md l-flex">
                  <CommaText :items="item.collectCompanyList" arg="name" :maxLength="15" />
                </div>
                <div class="c-infoList__cell md-s text-dotted">{{ item.disposalCompany.name }}</div>
                <div class="c-infoList__cell md-s text-dotted">
                  {{ item.disposalSiteAddressInfo.zipCode }}
                  {{ item.disposalSiteAddressInfo.prefecturesName }}
                  {{ item.disposalSiteAddressInfo.address }}
                  {{ item.disposalSiteAddressInfo.buildingName }}
                </div>
              </div>
            </div>
          </div>

          <div class="c-inputWrap">
            <div class="c-inputWrap__label">
              <label>上限数量</label>
            </div>
            <div class="c-inputWrap__items">
              {{ form.limitQuantity | formatFloat }}
              {{ form.limitQuantityUnitType ? form.limitQuantityUnitType.name : "" }}
            </div>
          </div>

          <div class="c-inputWrap">
            <div class="c-inputWrap__label">
              <label>協議書期間</label>
            </div>
            <div class="c-inputWrap__items">
              {{ form.priorConsultationStartDate | dateJpYMDddSlackFormat }}
              <span>~</span>
              {{ form.priorConsultationEndDate | dateJpYMDddSlackFormat }}
            </div>
          </div>

          <div class="c-inputWrap">
            <div class="c-inputWrap__label">
              <label>累積廃棄物数量</label>
            </div>
            <div class="c-inputWrap__items">
              {{ form.wasteQuantitySum | formatFloat }}
              {{ form.wasteQuantitySumUnitType ? form.wasteQuantitySumUnitType.name : "" }}
            </div>
          </div>

          <div class="c-inputWrap">
            <div class="c-inputWrap__label">
              <label>上限数量に対する割合</label>
            </div>
            <div class="c-inputWrap__items">
              {{ form.limitQuantityRate }} %
            </div>
          </div>

          <div class="c-inputWrap">
            <div class="c-inputWrap__label">
              <label>集計期間</label>
            </div>
          </div>
          <div
              v-for="item of this.form.countTermList"
              :key="item.countTermInfoId"
              class="c-inputWrap"
          >
            <div class="c-inputWrap">
              <div class="c-inputWrap__items">
                {{ item.countTermStartDate | dateJpYMDddSlackFormat }}
              </div>
              <span>~</span>
              <div class="c-inputWrap__items">
                {{ item.countTermEndDate | dateJpYMDddSlackFormat }}
              </div>

              <div class="c-inputWrap" style="margin-top:0">
                <div class="c-inputWrap__items__cols">
                  <label>警告基準値 :</label>
                </div>
                <div class="c-inputWrap__items__cols">
                  {{ item.alertValue }}
                  <label>%</label>
                </div>
              </div>

              <div class="c-inputWrap" style="margin-top:0">
                <div class="c-inputWrap__items__cols">
                  <label>廃棄物量 :</label>
                </div>
                <div class="c-inputWrap__items__cols">
                  {{ item.wasteQuantity }}
                  {{ form.wasteQuantitySumUnitType ? form.wasteQuantitySumUnitType.name : "" }}
                </div>
              </div>

              <div class="c-inputWrap" style="margin-top:0">
                <div class="c-inputWrap__items__cols">
                  <label>割合 :</label>
                </div>
                <div class="c-inputWrap__items__cols">
                  {{ item.wasteQuantityRate }}
                  <label>%</label>
                </div>
              </div>
            </div>
          </div>

          <div class="c-inputWrap">
            <div class="c-inputWrap__label">
              <label>対象契約</label>
            </div>
            <div class="c-inputWrap__items">
              {{ form.entrustIds ? form.entrustIds : "" }}
            </div>
          </div>

          <div class="c-inputWrap">
            <div class="c-inputWrap__label">
              <label>備考</label>
            </div>
            <div class="c-inputWrap__items">
              {{ form.remarks }}
            </div>
          </div>

          <div class="c-inputWrap">
            <div class="c-inputWrap__label">
              <label>添付ファイル</label>
            </div>
            <div v-if="form.fileName" class="c-inputWrap__items">
              <a @click="downloadFile(form.fileName)" class="c-text text-link c-entrustText__text">
                {{ form.fileName }}
              </a>
            </div>
            <div v-else class="c-inputWrap__items">-</div>
          </div>

        </div>

      </div>

      <FloatButtons>
        <div class="left">
          <router-link to="/priorconsultation" class="c-btn secondary small back">一覧へ戻る</router-link>
        </div>
        <div class="center">
          <div class="c-btn secondary small" @click="$router.push(`/priorconsultation/duplicate/${$route.params.id}`)">複製</div>
          <div class="c-btn secondary small" @click="$router.push(`/priorconsultation/edit/${$route.params.id}`)" :class="{ disabled: isDisableEditButton }">修正</div>
          <div class="c-btn secondary small" @click="isDeleteModalShow = true">削除</div>
        </div>
      </FloatButtons>
    </main>

    <!-- 削除確認モーダル -->
    <div class="c-modal" v-show="isDeleteModalShow">
      <div class="c-modal__overlay"></div>
      <div class="c-modal__content">
        <div class="c-modal__body">
          事前協議書を削除します。<br />
          よろしいですか？
        </div>
        <ModalFooterButtons :processing="processing">
          <div class="c-btn secondary small" @click="isDeleteModalShow = false">戻る</div>
          <div class="c-btn primary small" @click="deleteHandler">はい</div>
        </ModalFooterButtons>
      </div>
    </div>

    <AppFooter />
  </div>
</template>

<script>
import common from "@/mixin/common";
import callApi from "./mixins/callApi";
import ToastMessage from "@/components/parts/ToastMessage";
import FloatButtons from "@/components/layout/FloatButtons";
import ModalFooterButtons from "@/components/layout/ModalFooterButtons";
import CommaText from "@/components/parts/CommaText";

export default {
  name: "priorConsultation-detail",
  components: {FloatButtons, ToastMessage, ModalFooterButtons, CommaText},
  mixins: [callApi, common],
  data() {
    return {
      errMsgs: [],
      toastMsg: "",
      isDeleteModalShow: false,
      processing: false,
    };
  },
  computed: {
  },
  mounted() {
    this.priorConsultationDetailApi(this.$route.params.id);
  },
  methods: {
    // モーダル内登録押下
    deleteHandler() {
      this.deletePriorConsultationApi(this.$route.params.id);
    },

    // ファイルダウンロード
    downloadFile(fileName) {
      this.downloadPriorConsultationFileApi(this.$route.params.id, fileName);
    },
  },
};
</script>
